import React from 'react';
import {styled, useTheme} from "@mui/material";

import {Link} from "react-router-dom";

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import {Toolbar, Typography, Box} from "@mui/material";

import Divider from '@mui/material/Divider';

import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';


import { Search, Science, Person, Dataset } from "@mui/icons-material";
import {Route, Routes} from "react-router";
import ErrorePage from "./ErrorePage";
import NewSearch from "../Components/NewSearch";
import MainPage from "./mainPage";
import ListOfNiches from "../Components/ListOfNiches";
// import DashboardIcon from '@mui/icons-material/Dashboard';
import PermDeviceInformationOutlinedIcon from '@mui/icons-material/PermDeviceInformationOutlined';
import NicheInfo from "../Components/NicheInfo";
import WishList from "../Components/WishList";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});



const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));



const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


const Dashboard = () => {
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);

    // const handleDrawerOpen = () => {
    //     setOpen(true);
    // };

    const handleDrawerStatusChange = () => {
        setOpen(!open);
    };

    // const handleDrawerClose = () => {
    //     setOpen(false);
    // };



    return (
        <Box sx={{ display: 'flex' }}>

            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerStatusChange}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography  variant="h6" noWrap component="div">
                        Mini variant drawer
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerStatusChange}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>

                <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                    <ListItemButton sx={{ height: 48, mb: 1, justifyContent: open ? 'initial' : 'center', px: 2.5,}}
                                    component={Link} to="/DashBord/NS">
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }} >
                            <Search />
                        </ListItemIcon>
                        <ListItemText sx={{ opacity: open ? 1 : 0 }}> New Explore</ListItemText>
                    </ListItemButton>

                    <ListItemButton sx={{ minHeight: 48, mb: 1, justifyContent: open ? 'initial' : 'center', px: 2.5,}}
                                    component={Link} to="/DashBord/NichesList">
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }} >
                            <Dataset />
                        </ListItemIcon>
                        <ListItemText sx={{ opacity: open ? 1 : 0 }}> Niche list </ListItemText>
                    </ListItemButton>

                    <ListItemButton sx={{ minHeight: 48, mb: 1,  justifyContent: open ? 'initial' : 'center', px: 2.5,}}
                                    component={Link} to="/DashBord/WishList" >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }} >
                            <Science  />
                        </ListItemIcon>
                        <ListItemText sx={{ opacity: open ? 1 : 0 }}> Wish List </ListItemText>
                    </ListItemButton>

                    <Divider sx={{ mb: 1}}/>

                    <ListItemButton sx={{ minHeight: 48, mb: 1,  justifyContent: open ? 'initial' : 'center', px: 2.5,}}>
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }} >
                            <Person  />
                        </ListItemIcon>
                        <ListItemText sx={{ opacity: open ? 1 : 0 }}> Profile </ListItemText>
                    </ListItemButton>
                    <ListItemButton sx={{ minHeight: 48, mb: 1,  justifyContent: open ? 'initial' : 'center', px: 2.5,}}
                                    component={Link} to="/DashBord/TestNiche">
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }} >
                            <PermDeviceInformationOutlinedIcon  />
                        </ListItemIcon>
                        <ListItemText sx={{ opacity: open ? 1 : 0 }}> Test niche  </ListItemText>
                    </ListItemButton>
                </Box>



                {/*<Link to="/DashBord/NichesList">Link</Link>*/}

                    {/*<ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5,}}>*/}
                    {/*    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }} >*/}
                    {/*        <HourglassBottom  />*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText sx={{ opacity: open ? 1 : 0 }}> Test </ListItemText>*/}
                    {/*</ListItemButton>*/}
                {/*</Link>*/}









            </Drawer>

            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />

                <Routes>
                    <Route path="/NS" element={<NewSearch />} />
                    <Route path="/NichesList" element={<ListOfNiches />} />
                    <Route path="/" element={<MainPage />} />
                    <Route path="/TestNiche" element={<NicheInfo/>} />
                    {/*<Route path="/Profile" element={<ProfileComp/>} />*/}
                    <Route path="/WishList" element={<WishList />} />
                    <Route path="*" element={<ErrorePage />} />
                </Routes>
            </Box>


        </Box>
    );
};

export default Dashboard;
