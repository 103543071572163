
import './App.css';
import MainPage from "./Pages/mainPage";
import ErrorePage from "./Pages/ErrorePage";
import Dashboard from "./Pages/Dashboard";
import {Route, Routes} from "react-router";
import SignUp from "./Pages/SignUp";
import SignIn from "./Pages/SignIn";
import Login from "./Pages/Login";

function App() {
  return (
      <Routes>
          <Route path="" element={<MainPage/>} />
          <Route path="Dashbord/*" element={<Dashboard />} />
          <Route path="SignUp" element={<SignUp />} />
          <Route path="SignIn" element={<SignIn />} />
          <Route path="login" element={<Login />} />
          <Route path="*" element={<ErrorePage/>} />
      </Routes>

  );
}

export default App;
