import React from 'react';
import Img404 from '../Imeges/apps/undraw_page_not_found.svg';

import Div from "../@jumbo/shared/Div";
import {Button, Typography} from "@mui/material";

const ErrorePage = () => {
    return (

        <div className="center-info">
            <Div sx={{display: 'inline-flex', mb: 3}}>
                <img src={Img404} alt="404" width={380}/>
            </Div>
            <Typography align={"center"} variant={"h3"} color={"text.secondary"} mb={3}>
                Oops, an error has occurred. Page not found!
            </Typography>
            <Button variant="contained" >Go to home</Button>
        </div>
    );
};

export default ErrorePage;
