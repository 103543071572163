import React from 'react';
import {Button, TextField, Stack} from "@mui/material";
// import DeleteIcon from '@mui/icons-material/Delete';


import CasinoOutlinedIcon from '@mui/icons-material/CasinoOutlined';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';


const NewSearch = () => {
    return (
        <div className="new_search my-test-border">
            <div className="left-content">
                <TextField
                    fullWidth label="Name of a niche"
                    sx={{width: '25rem'}}
                    id="fullWidth_1" />
            </div>

            <TextField fullWidth label="Niche short description" id="fullWidth_2" />

            <div className="new-search-button-div">
                <Stack direction="row" spacing={3}>
                    <Button variant="outlined" startIcon={<EmojiObjectsOutlinedIcon />}>
                        Generate
                    </Button>
                    <Button variant="outlined" size="large" startIcon={<CasinoOutlinedIcon />}>
                        I'm feeling lucky
                    </Button>
                </Stack>

            </div>
        </div>
    );
};

export default NewSearch;
