import React from 'react';
import {Grid} from "@mui/material";
// import NicheCard from "./NicheCard";
import NichePreviewCard from "./NicheInfoComponent/NichePreviewCard";
// import Div from "@jumbo/shared/Div";

const testDataOfNiches = [
    {id: 1},
    {id: 2},
    {id: 3},
    {id: 4},
    {id: 5},
    {id: 6},
    {id: 7},
    {id: 8}
]

const ListOfNiches = () => {
    return (
        <Grid container spacing={6}>
            {testDataOfNiches.map((theNiche) =>
                <Grid item xs={12} md={6} lg={4}>
                    <NichePreviewCard key={theNiche.id}/>
                </Grid>
            )}
        </Grid>

        // <Grid container spacing={5}>
        //     <Grid item xs={6} md={4} lg={3} xl={2} >
        //         <NicheCard/>
        //     </Grid>
        //     <Grid item xs={6} md={4} lg={3} xl={2} >
        //         <NicheCard/>
        //     </Grid>
        //     <Grid item xs={6} md={4} lg={3} xl={2} >
        //         <NicheCard/>
        //     </Grid>
        //     <Grid item xs={6} md={4} lg={3} xl={2} >
        //         <NicheCard/>
        //     </Grid>
        //     <Grid item xs={6} md={4} lg={3} xl={2} >
        //         <NicheCard/>
        //     </Grid>
        //     <Grid item xs={6} md={4} lg={3} xl={2} >
        //         <NicheCard/>
        //     </Grid>
        //     <Grid item xs={6} md={4} lg={3} xl={2} >
        //         <NicheCard/>
        //     </Grid>
        //     <Grid item xs={6} md={4} lg={3} xl={2} >
        //         <NicheCard/>
        //     </Grid>
        //     <Grid item xs={6} md={4} lg={3} xl={2} >
        //         <NicheCard/>
        //     </Grid>
        //     <Grid item xs={6} md={4} lg={3} xl={2} >
        //         <NicheCard/>
        //     </Grid>
        //
        // </Grid>
    );
};

export default ListOfNiches;
