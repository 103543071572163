import React from 'react';

const NicheInfo = () => {
    return (
        <div>

            TestNiche

        </div>
    );
};

export default NicheInfo;
