import React from 'react';
import LoginForm from "../Components/LoginForm";

const Login = () => {
    return (
        <div>
            <LoginForm defActiveForm={false} />
        </div>
    );
};

export default Login;
