import React from 'react';
import {Grid} from "@mui/material";
import WishItem from "./WishItem";

const WishList = () => {

    const testData = [
        { id: 1, create_date: "23.04.2023", wish_niche_name: "Wish Name 01",
            wish_niche_description: " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias dolorum ",},
        { id: 2, create_date: "22.09.2023", wish_niche_name: "Wish Name 02",
            wish_niche_description: " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias dolorum eum expedita fuga mollitia nemo!",},
        { id: 3, create_date: "12.05.2023", wish_niche_name: "Wish Name 03",
            wish_niche_description: " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias dolorum eum expedita fuga mollitia nemo!",},
        { id: 4, create_date: "23.11.2023", wish_niche_name: "Wish Name 04",
            wish_niche_description: " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias dolorum eum expedita fuga mollitia nemo!",},
        { id: 5, create_date: "21.25.2023", wish_niche_name: "Wish Name 05",
            wish_niche_description: " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias dolorum eum expedita fuga mollitia nemo!",},
        { id: 6, create_date: "09.10.2023", wish_niche_name: "Wish Name 06",
            wish_niche_description: " consectetur adipisicing elit. Alias dolorum eum expedita fuga mollitia nemo!",},
        { id: 7, create_date: "11.12.2023", wish_niche_name: "Wish Name 07",
            wish_niche_description: " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias dolorum eum expedita fuga mollitia nemo!",},

    ];



    return (
        <Grid container spacing={7}>
            { testData.map((WishItemData) =>
                <Grid item xs={12} md={6} lg={4}>
                    <WishItem key={WishItemData.id} inData={WishItemData}/>
                </Grid>
            ) }
        </Grid>
    );
};

export default WishList;
