import React, {useState} from 'react';
import '../css/sign.css'
const LoginForm = (props) => {

    const [message, setMessage] = useState('');

    const [isSignUp, setIsSignUp] = useState(props.defActiveForm);


    const togglePanel = () => {
        console.log("dsdssdf")
        setIsSignUp((prevState) => !prevState);

    };

    // ------------------ start -  SignIn
    const [formSignIn, setFormSignIn] = useState({
        username: '',
        password: '',
    });


    const handleSignInChange = (e) => {
        const { name, value } = e.target;
        setFormSignIn({
            ...formSignIn,
            [name]: value,
        });
    };

    const handleSignInSubmit = async (e) => {
        e.preventDefault();
        console.log(' --- SignIn --- ')
        console.log(`------ ${JSON.stringify(formSignIn)} ------`)
        console.log(' --- SignIn --- ')
        // Add your form submission logic here
        // You can access the form data via the formData state
        var urlencoded = new URLSearchParams();
        urlencoded.append("username", formSignIn.username);
        urlencoded.append("password", formSignIn.password);
        try {
            const response = await fetch('https://niche.sandbox.vakhteev.com/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: urlencoded,
                redirect: 'follow'
            });

            if (response.ok) {

                try {
                    const data = await response.json();
                    console.log(data)
                    setMessage('Login successful');
                } catch (error) {
                    console.error("--------");
                    setMessage('credential error');
                    // Expected output: ReferenceError: nonExistentFunction is not defined
                    // (Note: the exact output may be browser-dependent)
                }

                // try {
                //
                //
                //
                // }
                // expect {
                //     setMessage('credantial error');
                // }


                // setMessage('Login successful');
                // setIsLoggedIn(true);

                // console.log('Data sent successfully');
                // console.log(response.text())
                // setPostData(response.text())

            } else {
                // Handle errors here
                console.error('Failed to send data');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // ------------------ end -  SignIn





    return (
        <div className="main-page">
            <div className={`container ${isSignUp ? 'right-panel-active' : ''}`}>
                <div>
                    <div className="form-container sign-up-container">
                        <form action="#">
                            <h1>Create Account</h1>

                            <span>or use your email for registration</span>
                            <input type="text" placeholder="Name"/>
                            <input type="email" placeholder="Email"/>
                            <input type="password" placeholder="Password"/>
                            <input type="password" placeholder="Password"/>
                            <button>Sign Up</button>
                        </form>
                    </div>
                    <div className="form-container sign-in-container">
                        <form onSubmit={handleSignInSubmit}>
                            <h1>Sign in</h1>
                            <span>or use your account</span>
                            <input type="text" id="SignIn_username" name="username"
                                   value={formSignIn.username}
                                   onChange={handleSignInChange}
                                   placeholder="Login" required/>

                            <input type="password" id="SignIn_password" name="password"
                                   value={formSignIn.password}
                                   onChange={handleSignInChange}
                                   placeholder="Password" required/>
                            {/*<a href="#">Forgot your password?</a>*/}
                            <button type="submit">Sign In</button>
                        </form>
                    </div>
                    <div className="overlay-container my-test-border">
                        <div className="overlay">
                            <div className="overlay-panel overlay-left">
                                <h1>Welcome Back!</h1>
                                <p>To keep connected with us please login with your personal info</p>
                                <button className="ghost" id="signIn" onClick={togglePanel} >Sign In</button>
                            </div>
                            <div className="overlay-panel overlay-right">
                                <h1>Hello, Friend!</h1>
                                <p>Enter your personal details and start journey with us</p>
                                <button className="ghost" id="signUp" onClick={togglePanel}>Sign Up</button>
                            </div>

                        </div>
                    </div>
                </div>


            </div>

            <p>--- {message} ---</p>


            {/*<div className="">*/}
            {/*    <button id="signUp" onClick={togglePanel}>Sign Up</button>*/}
            {/*    <button id="signIn" onClick={togglePanel}>Sign In</button>*/}
            {/*</div>*/}
        </div>

    );
};

export default LoginForm;
