import React from 'react';
import {Button, ButtonGroup, CardActions, styled, Typography, Card} from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
// import NicheCard from "./NicheCard";




const ActionButton = styled(Button)(({theme}) => ({
    padding: theme.spacing(1.5, 2),
    borderBottom: 'none',
    borderRadius: 0,
    textTransform: 'none',
    letterSpacing: 0,
    borderColor: theme.palette.divider,
    color: theme.palette.text.secondary,

    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderBottom: 'none',
    },
}));


const WishItem = function (props) {

    return (
        <div className='main-box-border'>
            <Card>
                <CardHeader
                    action={  <p className='header-data-style'> {props.inData.create_date}</p>  }
                    sx={{pb: 0}}
                > </CardHeader>
                <div className='wish-card-content '>
                    <Typography textAlign={"center"} sx={{ fontWeight: 700, fontSize: 'h5.fontSize' }}  mb={2}>{props.inData.wish_niche_name}</Typography>
                    <Typography textAlign={"justify"} mb={3}>{props.inData.wish_niche_description}</Typography>

                </div>

                <CardActions sx={{p: 0, mx: '-1px'}}>
                    <ButtonGroup size="large" fullWidth variant="outlined">
                        <ActionButton>Not interested</ActionButton>
                        <ActionButton>Generate</ActionButton>
                    </ButtonGroup>
                </CardActions>

            </Card>

        </div>

    )};

export default WishItem;
