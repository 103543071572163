import React from 'react';
import JumboCardFeatured from "@jumbo/components/JumboCardFeatured";
import {Button, Typography} from "@mui/material";
// import BgPicture from "../../Imeges/dashboard/ayurveda.jpg"

const NichePreviewCard = (props) => {
    return (
        <div className="main-box-border">
            <JumboCardFeatured

                showTick
                tickSx={{bgcolor: 'success.main'}}
                direction={"column"}
                textAlign={"center"}
                // imgSrc={BgPicture}
            >
                <h4 className='niche-card-header'> Niche name </h4>
                {/*<Typography  mb={2}>Ayurveda</Typography>*/}
                <p className='niche-card-description'> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad, culpa distinctio dolor fugit illo itaque necessitatibus nemo nisi nobis officia porro quasi qui reiciendis tempora tempore, velit voluptatum. Assumenda dolorem in magni neque voluptates? Ab. </p>
                <Typography textAlign={"justify"} mb={3}></Typography>
                <Button sx={{ mb: 2 }} variant={"contained"}>Learn More</Button>
            </JumboCardFeatured>
        </div>

    );
};

export default NichePreviewCard;
