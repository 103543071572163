import React from 'react';
import LoginForm from "../Components/LoginForm";
// import {TextField} from "@mui/material";


const SignUp = () => {




    // const [formData, setFormData] = useState({
    //     username: '',
    //     email: '',
    //     password: '',
    //     confirmPassword: '',
    // });
    //
    // const [loginFormData, setLoginFormData] = useState({
    //     username: '',
    //     password: '',
    // });
    //
    //
    // const handleChange = (e) => {
    //     const { name, value } =formData;
    //     setFormData({
    //         ...formData,
    //         [name]: value,
    //
    //     });
    //     console.log(`-- handleChange --  ${formData}`)
    // };
    //
    // const handleLoginChange = (e) => {
    //     const { name, value } = e.target;
    //     setLoginFormData({
    //         ...loginFormData,
    //         [name]: value,
    //     });
    //     console.log(`-- handleLoginChange --  ${loginFormData}`)
    // };
    //
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     // Add your form submission logic here
    //     // You can access the form data via the formData state
    // };
    //
    // const handleLoginSubmit = (e) => {
    //     console.log(`  -----  handleLoginSubmit  -----  ${handleLoginSubmit}`)
    //     e.preventDefault();
    // }

    return (
       <LoginForm defActiveForm={true} />

);
};

export default SignUp;
